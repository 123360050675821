import React from "react";
import "./Services.scss";

class Services extends React.Component {
  state = {
    isHovering: [],
    hover: false,
    services_in_view: 0,
  };

  componentDidMount() {
    this.setState(() => {
      this.isHovering = [false];
    });
    if (!this.isMobile) {
      window.addEventListener("scroll", this.handleScrollChange);
    }
  }

  handleScrollChange = (event) => {
    if (
      window.scrollY >= document.body.clientHeight / 14 &&
      this.state.services_in_view === 0
    ) {
      this.setState({ services_in_view: 1 });
    }
  };

  handleMouseOver = (index) => {
    const hoveringStates = this.state.isHovering;
    hoveringStates[index] = true;

    this.setState({
      isHovering: hoveringStates,
      hover: true,
    });
  };

  handleMouseOut = (index) => {
    const hoveringStates = this.state.isHovering;
    hoveringStates[index] = false;

    this.setState({
      isHovering: hoveringStates,
      hover: true,
    });
  };

  render() {
    return (
      <>
        {!this.isMobile && (
          <div className="services-heading">
            <div className="services-img-section">
              <div className="outer-frame">
                <div
                  className="bg bg-frame service-item"
                  id="metal-frame"
                  services_in_view={this.state.services_in_view}
                  onMouseOver={() => this.handleMouseOver(0)}
                  onMouseOut={() => this.handleMouseOut(0)}
                >
                  <h3>METAL FRAME</h3>
                  <p className="service-description noselect">
                    From drop ceilings to suspended ceilings, partition walls
                    and exterior framing, we provide quality workmanship for all
                    kinds of framing. Metal frame bulkheads installed with
                    L-track and furring channel assemblies can save that extra
                    needed space on a 8' basement ceiling or create fabulous
                    waffle ceiling designs. Install resilient
                    channel to new roof trusses to create less stress on the
                    drywall finish when your new foundation walls start to
                    settle to avoid cracked corners on the drywall finishing, to
                    level out warped floor joists, or to earn a better STC
                    rating for room or unit separation.
                  </p>
                  <div className="dark-fill"></div>
                  <div className="corner-poly">
                    <svg>
                      <polygon points="0,50 0,0 50,50" />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="outer-frame">
                <div
                  className="bg bg-spray service-item"
                  id="spray-foam"
                  services_in_view={this.state.services_in_view}
                  onMouseOver={() => this.handleMouseOver(1)}
                  onMouseOut={() => this.handleMouseOut(1)}
                >
                  <h3>SPRAY FOAM</h3>
                  <p className="service-description noselect">
                    Our team offers comprehensive information and guidance on
                    spray foam insulation. We provide detailed explanations
                    about the benefits, applications, and installation process
                    of spray foam insulation. We also share tips and insights on
                    choosing the right type of spray foam, as well as its
                    environmental impact. Whether you are a homeowner,
                    contractor, or industry professional, our goal is to educate
                    and inform you about the benefits and best practices of
                    utilizing spray foam insulation for energy efficiency and
                    sustainability.
                  </p>
                  <div className="dark-fill"></div>
                  <div className="corner-poly">
                    <svg>
                      <polygon points="0,50 0,0 50,50" />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="outer-frame">
                <div
                  className="bg bg-insulation service-item"
                  id="insulation"
                  services_in_view={this.state.services_in_view}
                  onMouseOver={() => this.handleMouseOver(2)}
                  onMouseOut={() => this.handleMouseOut(2)}
                >
                  <h3>INSULATION</h3>
                  <p className="service-description noselect">
                    Insulation is a must for providing the most comfort while
                    also provides cost-savings by making your home more
                    efficient to cooling and heating. The best scenario is a
                    home that is well sealed, well insulated and well
                    ventilated. Keep your house airtight by sealing and caulking
                    to all double, triple and quadruple stacks to avoid any air
                    loss and air drafts. With this combination of the proper
                    insulation, the comforts of being at home will never change
                    whether in the cool of winter or the heat of summer.
                  </p>
                  <div className="dark-fill"></div>
                  <div className="corner-poly">
                    <svg>
                      <polygon points="0,50 0,0 50,50" />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="outer-frame">
                <div
                  className="bg bg-drywall service-item"
                  id="drywall"
                  services_in_view={this.state.services_in_view}
                  onMouseOver={() => this.handleMouseOver(3)}
                  onMouseOut={() => this.handleMouseOut(3)}
                >
                  <h3>DRYWALL</h3>
                  <p className="service-description noselect">
                    Drywall is a light-weight and fire-resistent panel of gypsum
                    that is made even more fire-resistent when applying joining
                    gypsum plaster between two layers of taping paper. Drywall
                    is relatively inexpensive compared to the other building
                    methods which makes it the preferred finishing product for
                    the interior of any home or building. However, drywall is
                    not resistant to moisture, so it can quickly create mold or
                    mildew growth between the gypsum paper layers. This is why
                    we recommend use of green or blue boards inside rooms with
                    moisture build up such as washrooms, saunas and steam rooms.
                  </p>
                  <div className="dark-fill"></div>
                  <div className="corner-poly">
                    <svg>
                      <polygon points="0,50 0,0 50,50" />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="outer-frame">
                <div
                  className="bg bg-taping service-item"
                  id="taping"
                  services_in_view={this.state.services_in_view}
                  onMouseOver={() => this.handleMouseOver(4)}
                  onMouseOut={() => this.handleMouseOut(4)}
                >
                  <h3>TAPING</h3>
                  <p className="service-description noselect">
                    Drywall taping is the process of covering the joints, butt
                    joints, screw holes and seams in the drywall. This process
                    involves the use of <i>Durabond 90</i> to seal off all
                    joints, mesh tape and paper tape. Typically three coats of
                    the compound mix are necessary to leave a smooth finish
                    ready for priming and paint. Taping requires a high amount
                    of attention to detail and must follow industry guidelines
                    to ensure the correct amount of each coat to avoid any
                    unnecessary bumps or imperfections. It is seen as an art and
                    should be highly regarded when considering services from a
                    drywall company.
                  </p>
                  <div className="dark-fill"></div>
                  <div className="corner-poly">
                    <svg>
                      <polygon points="0,50 0,0 50,50" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Services;
