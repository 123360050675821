import React from "react";
import { Container } from "reactstrap";
import "./Industries.scss";

class Industries extends React.Component {
  render() {
    return (
      <>
        <Container>
          <div className="industry grid cols-21 grid-center">
            <div></div>
            <div className="industry-header">
              <h3 id="commercial">COMMERCIAL</h3>
              <h3 id="developments">DEVELOPMENTS</h3>
              <h3 id="custom-homes">CUSTOM HOMES</h3>
            </div>
            <div className="industry-description">
              <p>
                Providing our clients with premium services for over 10 years.
                Taking care of your project like its our home is our primary
                goal. We make sure everything we do always reaches the highest
                standards and keeps our customers satisfied.
              </p>
            </div>
          </div>
        </Container>
      </>
    );
  }
}

export default Industries;
