import React from "react";
import { Container } from "reactstrap";
import "./Values.scss";

class Values extends React.Component {
  isMobile = window.innerWidth <= 1200;
  render() {
    return (
      <>
        <Container>
          <div className="values grid cols-12 grid-center">
            {this.isMobile && (
              <div className="values-header">
                <h3>TRUST</h3>
                <h3>HONOR</h3>
                <h3>INTEGRITY</h3>
              </div>
            )}
            <div className="values-description">
              <p>
                Three fundamental values that we pride ourselves in building and
                bonding through relationships. Trust, Honor and Integrity are
                vital to any businesses success. A business that values these
                principals builds a reputation for reliability, higher standards
                and moral practices which lead to sustained growth and long term
                relationships.
              </p>
            </div>
            {!this.isMobile && (
              <div className="values-header">
                <h3>TRUST</h3>
                <h3>HONOR</h3>
                <h3>INTEGRITY</h3>
              </div>
            )}
          </div>
        </Container>
      </>
    );
  }
}

export default Values;
