import React from "react";
import ReactGA from "react-ga";
import "./Main.scss";

import { Container } from "reactstrap";

import SimpleNavbar from "components/Navbars/SimpleNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import SimpleGallery from "components/Gallery/SimpleGallery.js";
import Values from "components/MainPage/Breaker/Values.js";
import Services from "components/MainPage/Services/Services.js";
import ContactForm from "components/MainPage/ContactForm/ContactForm.js";
import Intro from "components/MainPage/Intro/Intro.js";
import Industries from "components/MainPage/Breaker/Industries";
import Clients from "components/MainPage/Breaker/Clients";
import VideoIntro from "components/MainPage/Breaker/VideoIntro";

class Landing extends React.Component {
  state = {
    contact_result_message: "",
    contact_result_color: "red",
    contact_email_valid: false,
    contact_form_valid: false,
    contact_email_style: "",
    email_name: "",
    email_address: "",
    email_message: "",
    phone_number: "",
    portfolio_in_view: 0,
    contact_in_view: 0,
  };

  isMobile = window.innerWidth <= 1280;

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (!this.isMobile) {
      window.addEventListener("scroll", this.handleScrollChange);
    } else {
      this.setState({ portfolio_in_view: 1, contact_in_view: 1 });
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScrollChange);
  }

  // TODO : might be better way to set animations for sections
  // Associates animations to the user's location on the page.
  handleScrollChange = (event) => {
    if (
      window.scrollY >= document.body.clientHeight / 2.25 &&
      this.state.portfolio_in_view === 0
    ) {
      this.setState({ portfolio_in_view: 1 });
    }
  };

  render() {
    return (
      <>
        {ReactGA.initialize(process.env.REACT_APP_GA_ID, {
          gaOptions: { siteSpeedSampleRate: 100 },
        })}
        <SimpleNavbar />
        <main ref="main">
          <section className="landing-start section bg-bright">
            <Container>
              <div className="header">
                {this.isMobile && (
                  <img
                    src={require("../../assets/img/logo/logo_dd_white_nobg.png")}
                    alt="logo"
                    className="img-header"
                  />
                )}
                <div className="subtitle-group">
                  <div className="pid">
                    <h2>PREMIUM INTERIOR DEVELOPMENT</h2>
                    <div className="gta">
                      <h3>GREATER TORONTO AREA</h3>
                    </div>
                  </div>
                </div>
              </div>
              {!this.isMobile && (
                <img
                  src={require("../../assets/img/logo/logo_dd_white_nobg.png")}
                  alt="logo"
                  className="img-header"
                />
              )}
            </Container>
          </section>
          {/* ---------------------------------------- INDUSTRIES ---------------------------------------- */}
          <section className="section bg-default">
            <VideoIntro />
          </section>
          <section className="industries-section section section-sm bg-primary">
            <Industries />
          </section>
          {/* ---------------------------------------- SERVICES ---------------------------------------- */}
          <section id="services" className="section bg-brighter">
            <Container className="services-section">
              <h2 className="services-title">SERVICES</h2>
              <Services />
            </Container>
          </section>
          <section className="values-section section section-sm bg-primary index-bottom">
            <Values />
          </section>

          <section id="about-us" className="intro-section section bg-default">
            <Intro />
          </section>
          {/* ---------------------------------------- PROJECTS ---------------------------------------- */}
          <section id="projects" className="section section-lg bg-brighter">
            <Container
              className="portfolio"
              portfolio_in_view={this.state.portfolio_in_view}
            >
              <h2 className="text-primary text-center projects-title">
                PROJECTS
              </h2>
              <div className="gallery">
                <SimpleGallery />
              </div>
            </Container>
          </section>
          {/* ---------------------------------------- CLIENTS ---------------------------------------- */}
          <section className="clients-section section section-sm bg-default">
            <Clients />
          </section>
          {/* ---------------------------------------- CONTACT US ---------------------------------------- */}
          <section
            id="contact"
            className="section section-lg section-contact-us pt-200 bg-brighter"
          >
            <Container className="">
              <ContactForm />
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Landing;
