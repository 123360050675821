import React from "react";
import { Container } from "reactstrap";
import "./Intro.scss";

class Intro extends React.Component {
  state = {
    intro_in_view: 0,
  };

  componentDidMount() {
    if (!this.isMobile) {
      window.addEventListener("scroll", this.handleScrollChange);
    }
  }

  handleScrollChange = (event) => {
    if (
      window.scrollY >= document.body.clientHeight / 3 &&
      this.state.intro_in_view === 0
    ) {
      this.setState({ intro_in_view: 1 });
    }
  };

  render() {
    return (
      <>
        <Container>
          <div className="intro">
            <div className="intro-header noselect">
              <h2 id="why" intro_in_view={this.state.intro_in_view}>
                WHY
              </h2>
              <h2
                id="us"
                intro_in_view={this.state.intro_in_view}
                style={{ textAlign: "right" }}
              >
                US
              </h2>
            </div>
            <div className="details">
              <div>
                <span className="label">High-quality Workmanship</span>
                <p>
                  Our team of skilled and experienced professionals is dedicated
                  to providing the best possible workmanship.
                </p>
              </div>
              <div>
                <span className="label">Flexibility</span>
                <p>
                  We are flexible and work around your schedule to ensure that
                  your project is completed on time.
                </p>
              </div>
              <div>
                <span className="label">Communication</span>
                <p>
                  We maintain strong communication with our clients to ensure
                  that they are updated on the progress of their project.
                </p>
              </div>
              <div>
                <span className="label">Attention to detail</span>
                <p>
                  We pay attention to every detail and ensure that your project
                  is completed to perfection.
                </p>
              </div>
              <div>
                <span className="label">Competitive pricing</span>
                <p>
                  We offer competitive pricing without compromising on the
                  quality of our work.
                </p>
              </div>
              <div>
                <span className="label">Timely completion</span>
                <p>
                  We understand the value of your time and ensure that your
                  project is completed within the designated timeframe.
                </p>
              </div>
              <div>
                <span className="label">Expert advice</span>
                <p>
                  We provide expert advice on Insulation/sprayfoam, drywall
                  completion, maintenance and service to help you make informed
                  decisions that best suit your needs.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </>
    );
  }
}

export default Intro;
