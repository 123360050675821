import React from "react";
import { Container } from "reactstrap";
import "./Clients.scss";

class Clients extends React.Component {
  render() {
    return (
      <>
        <Container className="client-container">
          <div className="client-grid grid grid-center">
            <div className="header-placeholder" />
            <div className="client-header">
              <h2 hidden>Our Clients</h2>
              <p>CLIENTS</p>
              <p>WHO</p>
              <p>TRUST US</p>
            </div>
            <div className="client-icons">
              <img
                alt="profile developments logo"
                className="client-logo"
                src={require(`../../../assets/img/client_logos/1.png`)}
              />
              <img
                alt="chatsworth logo"
                className="client-logo"
                src={require(`../../../assets/img/client_logos/2.png`)}
              />
              <img
                alt="bachly construction logo"
                className="client-logo"
                src={require(`../../../assets/img/client_logos/3.png`)}
              />
              <img
                alt="old orchard homes logo"
                className="client-logo"
                src={require(`../../../assets/img/client_logos/4.png`)}
              />
              <img
                alt="khcl logo"
                className="client-logo"
                src={require(`../../../assets/img/client_logos/5.png`)}
              />
              <img
                alt="anix developments logo"
                className="client-logo"
                src={require(`../../../assets/img/client_logos/6.png`)}
              />
              <img
                alt="trimatrix construction logo"
                className="client-logo"
                src={require(`../../../assets/img/client_logos/7.png`)}
              />
              <img
                alt="gsm contracting logo"
                className="client-logo"
                src={require(`../../../assets/img/client_logos/8.png`)}
              />
              <img
                alt="vaughan build logo"
                className="client-logo"
                src={require(`../../../assets/img/client_logos/9.png`)}
              />
              <img
                alt="caledon build logo"
                className="client-logo"
                src={require(`../../../assets/img/client_logos/10.png`)}
              />
            </div>
          </div>
        </Container>
      </>
    );
  }
}

export default Clients;
