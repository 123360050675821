import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Landing from './views/Main';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/scss/argon-design-system-react.scss?v1.1.0";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route
        path="/"
        exact
        render={props => <Landing {...props} />}
      />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);
