import React from "react";
import GoogleMapReact from "google-map-react";
import "./Map.scss";

const markerLocation = { lat: 43.788288, lng: -79.5578677 };

function renderMarkers(map, maps) {
	let marker = new maps.Marker({
		position: markerLocation,
		map,
		title: "Damasio Drywall",
	});

	return marker;
}

class GMap extends React.Component {
	static defaultProps = {
		center: {
			lat: 43.788288,
			lng: -79.5578677,
		},
		zoom: 14,
	};

	render() {
		return (
			<div className="map">
				<GoogleMapReact
					bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API }}
					defaultCenter={this.props.center}
					defaultZoom={this.props.zoom}
					yesIWantToUseGoogleMapApiInternals
					onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
				></GoogleMapReact>
			</div>
		);
	}
}

export default GMap;
