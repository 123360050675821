import React from "react";
import { Container } from "reactstrap";
import "./VideoIntro.scss";

class VideoIntro extends React.Component {
  render() {
    return (
      <>
        <Container>
          <div className="video-intro">
            <iframe
              src="https://www.youtube.com/embed/LE-VZgtweDQ"
              title="Damasio Drywall: Premium Metal Frame, Insulation, Spray Foam, Drywall and Taping Services in Toronto"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </Container>
      </>
    );
  }
}

export default VideoIntro;
